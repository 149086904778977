<template>
  <q-input v-model="colour" v-bind="$attrs">
    <template v-slot:append>
      <q-avatar :style="{ background: colour }" class="shadow-2 text-grey-4">
        <q-icon name="colorize" class="cursor-pointer" :rules="['anyColor']">
          <q-popup-proxy v-bind="pickerOptions">
            <q-color
              v-model="colour"
            />
          </q-popup-proxy>
        </q-icon>
      </q-avatar>
    </template>
  </q-input>
</template>
<script>
export default {
  name: 'MFieldColourPicker',
  props: {
    value: String,
    pickerOptions: {
      type: Object,
      default: () => {}
    },
    default: {
      type: String,
      default: '#000000'
    }
  },
  computed: {
    colour: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      }
    }
  },
  created () {
    if (!this.colour && this.default) this.colour = this.default
  }
}
</script>
